import React from 'react';
import s from './Input.module.scss';

interface IProps {
  value: string;
  onChange: (newValue: string) => void;
  label: string;
  hasErrors: boolean;
  autoComplete?: 'on' | 'off'
}

const Input = (props: IProps) => { 
  return (
    <div className={s.inputWrapper}>
      {/* <label className={s.label}>{props.label}</label> */}
      <input
        placeholder={props.label}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        className={s.input + ' ' + (props.hasErrors ? s.error : '')}
        autoComplete={props.autoComplete ? props.autoComplete : 'on'}
      />
    </div>
  )
}

export default Input;