import React from 'react';
import s from './RerollSelect.module.scss';
import leftSvg from 'Assets/icons/arrowLeft.svg';
import rightSvg from 'Assets/icons/arrowRight.svg';
import pencilSvg from 'Assets/icons/pencil.svg';
import reSvg from 'Assets/icons/re.svg';
import checkSvg from 'Assets/icons/check.svg';
import xSvg from 'Assets/icons/x.svg';

interface IProps {
  x: number;
  y: number;
  onSelect: () => void;
  onEdit: () => void;
  onNext: () => void;
  onPrev: () => void;
  onRegenerate: () => void;
  onCancel: () => void;
  imageUrls: string[];
  selectedUrl: string;
}

const RerollSelect = (props: IProps) => {
  const count = props.imageUrls.length;
  const selectedIndex = props.imageUrls.findIndex(url => url === props.selectedUrl);

  return (
    <div className={s.content} style={{top: props.y, right: props.x}}>
      <section className={s.leftSection}>
        <button onClick={() => props.onPrev()}>
          <img src={leftSvg} />
        </button>
        <span className={s.infoBlock}>{selectedIndex + 1}/{count}</span>
        <button onClick={() => props.onNext()}>
          <img src={rightSvg} />
        </button>
      </section>
      <section className={s.rightSection}>
        <button onClick={() => props.onEdit()}>
          <img src={pencilSvg} />
        </button>
        <button onClick={() => props.onRegenerate()}>
          <img src={reSvg} />
        </button>
        <button onClick={() => props.onSelect()}>
          <img src={checkSvg} />
        </button>
        <button onClick={() => props.onCancel()}>
          <img src={xSvg} />
        </button>
      </section>
    </div>
  )
}

export default RerollSelect;