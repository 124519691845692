import Spinner from 'Components/Common/Spinner/Spinner';
import React, { useState, useRef, useEffect } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import ChapterItem from '../ChapterItem/ChapterItem';
import ContentEditableDiv from '../ContentEditableDiv/ContentEditableDiv';
import { IBookChapter } from '../types';
import s from './BookInfoBlock.module.scss';
import { ReactComponent as AddNewChapter } from '../../../Assets/addNewChapter.svg';
import Button from 'Components/Common/Button/Button';
import clsx from 'clsx';
import ChapterPlaceholders from '../ChapterPlaceholders/ChapterPlaceholders';
import { rxActiveChapterIndex } from 'rx/rxState';

interface IProps {
  bookTitle: string;
  onBookTitleEdited: (value: string) => void;
  onChapterTitleEdited: (value: string, index: number) => void;
  chapters: IBookChapter[];
  loadingChapter: boolean;
  loadingAddChapter: boolean;
  deleteChapter: (index: number) => void;
  onChapterAdd: (indexBefore: number, action: 'add' | 'insert') => void;
  reorderChapter: (dragIndex: number, dropIndex: number) => void;
  endReorderChapter: () => void;
  onNoteAdd: (chapterIndex: number, noteText: string) => void;
  onGenerateBook: () => void;
}

function BookInfoBlock({
  bookTitle,
  onBookTitleEdited,
  chapters,
  loadingChapter,
  onChapterTitleEdited,
  deleteChapter,
  onChapterAdd,
  reorderChapter,
  endReorderChapter,
  loadingAddChapter,
  onNoteAdd,
  onGenerateBook,
}: IProps) {
  const [bookTitleInput, setBookTitleInput] = useState<string>(bookTitle);
  const [activeChapter, setActiveChapter] = useState<number>(0);
  const [isBookTitleActive, setIsBookTitleActive] = useState<boolean>(false);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [lastSortingTimestamp, setLastSortingTimestamp] = useState<
    number | null
  >(null);

  useEffect(() => {
    setBookTitleInput(bookTitle);
  }, [bookTitle]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      const currentTime = Date.now();
      if (lastSortingTimestamp && currentTime - lastSortingTimestamp > 1000) {
        endReorderChapter();
      }
    }, 1000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [lastSortingTimestamp]);
  const handleNameInput = (value: string) => {
      const words = value.split(" "); 
      const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
          return word[0].toUpperCase() + word.slice(1); 
        }
        return word;
      });
      const capitalizedValue = capitalizedWords.join(" "); 
    
      setBookTitleInput(capitalizedValue);
    };
  const handleChapterSorting = (res: DropResult) => {
    const { destination, source, draggableId } = res;

    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    setLastSortingTimestamp(Date.now());
    reorderChapter(source.index, destination.index);
  };
  return (
    <div>
      <div className={s.bookInfoEditNameInput}>
        <div className={s.bookInfoEditNameInputHeader}>Your Book Title</div>
        <ContentEditableDiv
          name={bookTitleInput}
          fontSize={30}
          lineHeight={42}
          fontWeight={500}
          width={500}
          height={37}
          onChange={handleNameInput}
          onEdit={onBookTitleEdited}
        />
      </div>
      <DragDropContext onDragEnd={handleChapterSorting}>
        <Droppable droppableId="chapters">
          {(provided) => (
            <div
              className={s.chaptersBlock}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {loadingChapter ? (
                <ChapterPlaceholders />
              ) : (
                chapters.map((chapter: IBookChapter, index) => (
                  <Draggable
                    key={chapter.title + index.toString()}
                    draggableId={`chapter-${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ChapterItem
                          index={index}
                          chapter={chapter}
                          onChapterTitleEditedProps={onChapterTitleEdited}
                          activeChapter={activeChapter}
                          setActiveChapter={setActiveChapter}
                          deleteChapter={deleteChapter}
                          onChapterAdd={onChapterAdd}
                          loadingAddChapter={loadingAddChapter}
                          onNoteAdd={onNoteAdd}
                          isBookTitleActive={isBookTitleActive}
                          setIsBookTitleActive={setIsBookTitleActive}
                        />
                      </div>
                    )}
                  </Draggable>
                ))
              )}
              {!loadingChapter && (
                <div
                  className={s.addNewChapter}
                  onClick={() => onChapterAdd(chapters.length, 'add')}
                >
                  {loadingAddChapter ? (
                    <Spinner size={30} />
                  ) : (
                    <AddNewChapter />
                  )}
                </div>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!loadingChapter && (
        <div className={s.generateBookButtonsBlock}>
          <Button
            size="regular"
            color="accent"
            onClick={() => onGenerateBook()}
            borderRadius={'25px'}
          >
            Generate
          </Button>
        </div>
      )}
    </div>
  );
}

export default BookInfoBlock;
